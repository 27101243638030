<script>
	import localService from "@/service"
	import VueEvent from '../service/events'
	import QRCode from 'qrcodejs2';
	import {
		ref
	} from 'vue'

	const count = ref(0)
	export default {
		data() {
			return {
				date: new Date().getFullYear(),
				qrCode: null,
				slides: [
				'First',
				'Second',
				'Third',
				'Fourth',
				'Fifth',
				],
			}
		},
		mounted() {
			if(this.qrCode==null){
				this.$nextTick(function() {
					this.bindQRCode();
				})
			}
		},
		methods: {
			service(){
				
			},
			bindQRCode: function() {
				/*
				this.qrCode = new QRCode(this.$refs.qrCodeDiv, {
					text: 'https://work.weixin.qq.com/kfid/kfcc2f1f4d9617d7bd4',
					width: 120,
					height: 120,
					colorDark: "#333333", //二维码颜色
					colorLight: "#ffffff", //二维码背景色
					correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
				})*/
			},
			linkTo(path){
				this.$router.push({
					path: path,
					query: {}
				}, ()=>{}, ()=>{})
			},
			toFavs(){
				if(localService.utils.isUserLogin()){
					this.linkTo("/favs")
				}else{
					VueEvent.emit("execLogin","/favs");
				}
			}
		}
	}
</script>

<template>
	<div class="container-main">
		<div class="footer">
			<div class="left">
				<div><h2>ISO文档交流平台</h2></div>
				<div class="line">超凡软件&nbsp;&nbsp;&copy;版权所有 2022</div>
				<div class="line">豫ICP备2021019445号-1</div>
				<div class="line">电子邮箱:easy9001@163.com</div>
				<div class="qrCode">
					<img src="../assets/qrcode_service.png" />
					<!--
					<div id="qrCode" ref="qrCodeDiv"></div>
					-->
					<p>微信扫码 咨询客服</p>
					
				</div>
			</div>
		
			<div class="center">
				<h2>产品</h2>
				<ul>
					<li>质量管理体系</li>
					<li>质量、环境管理体系</li>
					<li>环境管理体系</li>
					<li>环境、职业健康安全管理体系</li>
					<li>质量、环境、健康三体系</li>
					<li>建筑工程质量体系</li>
					<li>能源管理体系</li>
				</ul>
			</div>
			
			<div class="adv">
				<h2>ISO文档助手(企业解决方案)</h2>
				<template>
					<el-carousel height="200px" direction="vertical" :autoplay="true">
						<el-carousel-item>
							<h3>管理体系认证文档编写专家</h3>
							<h3>集合“四”大优势</h3>
							<h3>多、快、好、省</h3>
						</el-carousel-item>
						<el-carousel-item>
							<h3>全方位重构ISO认证咨询新体验</h3>
							<h3>彻底解决咨询难、成本高的问题</h3>
						</el-carousel-item>
					</el-carousel>
					
				</template>
			</div>

			<div class="right">
				<img src="../assets/qrcode_ent.png" />
				<p>扫一扫添好友了解详情</p>
			</div>
			
		</div>
		<!--
			//豫ICP备2021019445号-1 超凡软件 easy9001.cn
			//豫ICP备2023018742号-1 宸思数据 easy9001.com.cn
			//
		<div class="copyright">
			Copyright &copy;2016-{{date}} 豫ICP备16053114号-2 &copy;{{date}} 河南宸思数据科技有限公司 版权所有
		</div>
		-->
	</div>
</template>
<style scoped>
	.container-main {
		width: 100vw;
		height: 250x;
		background-color: #ffffff;
		color: #000;
		margin-top: 25px;
	}

	.container-main .footer {
		width: 80vw;
		min-width: 1200px;
		height: 250px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		flex-direction: row;
	}
	
	.container-main .footer .left {
		position: relative;
		width: 15%;
		padding: 20px 10px 0px 10px;
		text-align: left;
	}

	.container-main .footer .left h2{
		font-size: 18px;
		font-weight: bold;
		margin: 15px 0px;
	}
	
	.container-main .footer .left .line{
		font-size: 14px;
		height: 36px;
		line-height: 36px;

	}

	.container-main .footer .left .email i {
		width: 36px;
		height: 36px;
		border-radius: 18px;
		background: #fff;
		color: #333;
		font-size: 22px;
		margin-right: 20px;
	}

	.container-main .footer .left button {
		width: 100%;
		margin-top: 25px;
		line-height: 40px;
		background: #377cf7;
		border-radius: 5px;
		font-size: 14px;
		box-shadow: 0 0 5px rgba(255, 255, 255, .2);
	}

	.container-main .footer .left button:hover {
		box-shadow: 0 0 10px rgba(255, 255, 255, .25);
	}
	
	.container-main .footer .left .qrCode{
		background: #fff;
		text-align: center;
		padding: 20px 20px 15px 20px;
		position: absolute;
		left: 96%;
		top: 30px;
		border-radius: 5px;
		margin-left: 20px;
		display: block;
	}
	
	.container-main .footer .left .qrCode::before{
		content: "";
		display: block;
		width: 0;
		height: 0;
		border-width: 8px;
		border-style: solid;
		border-color: transparent #fff transparent transparent;
		position: absolute;
		left: -16px;
		top: 103px;
	}
	
	.container-main .footer .left .qrCode p{
		margin: 10px 0 0 0; 
		color: #1b66e6;
		font-size: 14px;
	}
	
	.container-main .footer .left button:hover+.qrCode{
		display: block;
	}

	.container-main .footer .adv {
		margin-right: -120px;
		width: 210px;
		text-align: left;
		ul{
			list-style: none;
		}
	}

	.container-main .footer .adv h2 {
		font-size: 14px;
		font-weight: bold;
		margin: 35px 0 15px 0;
	}

	.container-main .footer .adv h3{
		font-size: 14px;
		font-weight: normal;
		height: 27px;
		line-height: 27px;
	}

	.container-main .footer .adv .title{
		font-size: 18px;
		font-weight: bold;
		text-align: center;
		padding: 10px 10px;
	}
	
	.container-main .footer .center{
		margin-left: 80px;
		text-align: left;
	}
	
	.container-main .footer .center h2 {
		font-size: 14px;
		font-weight: bold;
		margin: 35px 0 15px 0;
	}

	.container-main .footer .center ul {
		font-size: 14px;
		color: #333;
		list-style: none;
		padding: 0;
		width: 420px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.container-main .footer .center ul li {
		margin-bottom: 10px;
		width: 50%;
	}

	.container-main .footer .center p {
		font-size: 14px;
		color: #778dac;
		margin-bottom: 10px;
		cursor: pointer;
	}
	.container-main .footer .center p:hover{
		color: #9bb2d3;
	}

	.container-main .footer .right {
		text-align: center;
	}

	
	.container-main .footer .qrCode img {
		display: block;
		width: 140px;
		margin: 0px auto 14px auto;
	}

	.container-main .footer .right img {
		display: block;
		width: 140px;
		margin: 40px auto 14px auto;
	}

	.container-main .footer .right p {
		font-size: 14px;
		color: #5c6e88;
		margin: 0;
	}

	.container-main .copyright {
		width: 100vw;
		line-height: 56px;
		text-align: center;
		font-size: 13px;
		color: #5c6e88;
		background-color: #222f40;
	}
</style>